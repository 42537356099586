import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import file from "./assets/lang.json";
import subjects from "./assets/subjects.json";
import Network from './network/networkRequests';
const CONFIG_KEY = "configs1.46";

if (!localStorage.getItem(CONFIG_KEY)) {
  localStorage.setItem("version", "1.46");
  localStorage.setItem(CONFIG_KEY,JSON.stringify(file));
  localStorage.setItem("subjs", JSON.stringify(subjects));

  localStorage.setItem("MAIN_URL","https://api.eureca.ao/v0.1");
}

function hasJWT(){
  return localStorage.getItem("token")?true:false;
}

const Anonimuos = async ()=>{
  if(hasJWT()) return;

  var data = JSON.stringify({
    "username": "anonimius@any.com",
    "password": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9"
  });
  
  const response =  await Network.sendRequest('post', '/login', data);
    if(response.data?.error){
      console.log("ERROR",response.data);
    }else{
      localStorage.setItem("token",response.data.token);
      localStorage.setItem("refreshToken",response.data.refreshToken);
      window.location.href = '/';
    }
};

Anonimuos();

const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}
root.render(
  <React.StrictMode>
   <AlertProvider template={AlertTemplate} {...options}>
    <App />
  </AlertProvider>
  </React.StrictMode>
);
reportWebVitals();
