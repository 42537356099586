import React from 'react';
import { Typography } from '@mui/material';

const TruncatedText = (props) => {
  const {text, limit,fontSize,fontWeight} = props;

  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  };

  return (
    <Typography  sx={{ whiteSpace: 'nowrap'}} variant="body2"  style={{fontSize:fontSize??"12px", fontWeight:fontWeight??"normal"}}>
      {truncateText(text, limit)}
    </Typography>
  );
};

export default TruncatedText;
