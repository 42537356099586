import React from 'react';
import { useEffect,useRef } from 'react';
import {Link,useParams} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Dropdown from 'muicss/lib/react/dropdown';
import { jwtDecode } from "jwt-decode";

import HashLoader from "react-spinners/HashLoader"; 
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Chip from '@mui/material/Chip';
import { getDatabase, ref, set,get,onValue,child} from "firebase/database";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarPg from './calendar'
import Payment from '../modules/payment';
import Messages from '../modules/Messages';
import Network from '../network/networkRequests';

var CurrencyFormat = require('react-currency-format');

var axios = require('axios');
function getTimeString(minutes) {
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} dia${days === 1 ? '' : 's'}`;
  } else if (hours > 0) {
    return `${hours} hour${hours === 1 ? '' : 's'}`;
  } else {
    return minutes+' minutos';
  }
}



export default function Util(){

}

export const timeSet = (data)=>{
  if(data == undefined)
  return 0;
  var value = (-deffdate(data));
  const rtfolder = new Intl.RelativeTimeFormat("pt", { numeric: "auto" });
  const rtf = new Intl.RelativeTimeFormat("pt")
  // const hr = rtf.format(parseInt(value),"hour")
  // const min = rtf.format(parseInt(value),"minute")
  var curr = rtf.format(parseInt(value),"day")
  var current = rtfolder.format(parseInt(value), "day");
  return (current);
}


const deffdate =(startdate)=>{
  var today = new Date();
  const diffInMs   = new Date(today) - new Date(startdate)
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  return diffInDays;
} 

var token = localStorage.getItem('token');
var refreshtoken = localStorage.getItem('refreshToken');

export const headers = {
    'Content-Type':'application/json; charset=utf-8',
    'Authorization':'Bearer '+token,
    'refreshToken':'Bearer '+refreshtoken,
}

export function CardInstructor({item}){
    return (<React.Fragment>
                        {/* Card item START */}
                        <div className="col-lg-10 col-xl-6">
                    <div className="card shadow p-2">
                    <div className="row g-0">
                        {/* Image */}
                        <div className="col-4 bg-dark h-150px" style={{background:"url("+(item.profileImage)+")", backgroundSize:'cover'}}>
                       
                        </div>
                        {/* Card body */}
                        <div className="col-8">
                        <div className="card-body">
                            {/* Title */}
                            <div className="d-sm-flex justify-content-sm-between mb-2 mb-sm-3">
                            <div>
                                <h5 className="card-title mb-0">
                                  <Link to={"/tutors/profile/"+item.id}>{item.nome} {item.ultimoNome}</Link>
                                </h5>
                                <p className="small mb-2 mb-sm-0">
                                  {item.ocupacao}
                                </p>
                            </div>
                            </div>
                            {/* Content */}
                            <p className="text-truncate-2 mb-3">
                                {item.descricao}
                            </p>
                            {/* Info */}
                            <div className="d-sm-flex justify-content-sm-between align-items-center">
                            {/* Title */}
                            <h5 className="mainTextColor mb-0">
                                <CurrencyFormat value={item.price??0} displayType={'text'} format="#.###.00 Kzs"/>
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Card item END */}
    </React.Fragment>);
}


// export function MessageCard({item,delected}){
//    return (<React.Fragment>
//    <Accordion className='shadow-none'>
//        <AccordionSummary
//          // expandIcon={<ExpandMoreIcon />}
//          aria-controls="panel1a-content"
//          id="panel1a-header"
//          className='bg-none border-0'
//        >
//           <div className="d-flex align-items-center position-relative gap-3">
//                        {/* Image */}
//                        <div className="avatar mb-2 mb-md-0 rounded-circle" style={{background:"url('"+(item?.user?.profileImage==""?"https://ui-avatars.com/api/?name="+item.user?.nome:item.user?.profileImage)+"')",backgroundSize:"cover"}}>
//                        </div>
//                        <div className="mb-0 ms-2">
//                          {/* Title */}
//                          <h6 className="mb-0">
//                         {item?.user?.nome} {item?.user?.ultimoNome}
//                          </h6>
//                        </div>
//                        Mensagem
                       
//                      </div>
//        </AccordionSummary>
//        <AccordionDetails>
//          <Typography>
//          {item.details}
//          </Typography>
//        </AccordionDetails>
//      </Accordion>
                       
             
//    </React.Fragment>);
// }
export function Submenu({dropdownItems}){
    return(<React.Fragment>
            <div className='mainColor h-40px'>
            <div className="container d-flex justify-content-between">
                <div className="d-flex">
                <button
              className="navbar-toggler ms-sm-auto bg-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-animation">
                <span />
                <span />
                <span />
              </span>
            </button>
                </div>                
            </div>
          </div>
    </React.Fragment>);
}

export function getDecodedToken(){
    if(!localStorage.key("token") || !localStorage.getItem("token")){
      // localStorage.clear();
      return "error";
    }

    const base_user_info = localStorage.getItem("token")?jwtDecode(localStorage.getItem("token")):{};
    return base_user_info;
}

export function pathArray(){
  const arr = ["/singIn","/singup","/dashboard","/dashboard/invits"];
  return arr;
}

export const filterBySharedSkills = (list, idToFilter) => {
  try{
  // Encontra o item com o idToFilter
  const selectedItem = list.find(item => item.id == idToFilter);
  if (!selectedItem) return []; // Retorna vazio se o item não for encontrado

  // Extrai as skills do item selecionado
  const selectedSkills = selectedItem.skills.map(skill => skill.title);

  // Verifica se outros itens compartilham alguma skill com o item selecionado
  const sharedItems = list.filter(item =>
      item.id != idToFilter && item.skills.some(skill => selectedSkills.includes(skill.title)) // Verifica habilidades em comum
  );

  // Retorna todos os itens se houver habilidades em comum, ou apenas o item selecionado
  return sharedItems.length > 0 ? list : [selectedItem];
  }catch(e){
    return [];
  }
};



export function Simpleuser({item}){
  return (
    <div className='d-flex align-items-center'>
      <img className='w-50px h-50px rounded-circle m-2 border bg-dark'src={item?.profileImage} alt="" srcset="" />
      <div>
        <div className='weight-bold text-capitalize'>{item?.nome} {item?.ultimoNome}  </div>
        <div className='small'>{item?.isOnline == undefined?"":item?.isOnline?"Online":"Online a "+getTimeString(item?.last_activity_minutes)+""}</div>
      </div>
    </div>
  );
}

// export function LeftManu(props){
//   const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
//   const location = useLocation();


//   return (<React.Fragment>
//            <div className='min-vw-50px'>
//                 <div className="m-0">
//                   <div className="">
//                     <div className="offcanvas-body mt-3">
//                       <div className="bg-dark border rounded-3 pb-0 p-3 w-100">
//                         <div className={isMobile?"d-flex list-group-dark list-group-borderless justify-content-between":"list-group list-group-dark list-group-borderless"}>

//                           <Link to="/dashboard/invits"  className={location.pathname.includes("invits")?"list-group-item d-flex gap-1 bg-white text-dark":"list-group-item d-flex gap-1"} style={{whiteSpace:"nowrap"}}><i className="bi bi-bell"></i> <span className='d-none d-md-block'>Notificações</span></Link>
//                           {/* <Link to="/dashboard/messages" className={location.pathname.includes("messages")?"list-group-item d-flex gap-1 bg-white text-dark":"list-group-item d-flex gap-1"}><i className="bi bi-chat-dots"></i> <span className='d-none d-md-block'>Messagens</span></Link> */}
//                           {props.simpleProfile.role == ""? <>
                        
//                           {/* <Link to="/dashboard/calendar" className={location.pathname.includes("calendar")?"list-group-item d-flex gap-1 bg-white text-dark":"list-group-item d-flex gap-1"} style={{whiteSpace:"nowrap"}}><i className="bi bi-calendar-event"></i><span className='d-none d-md-block'>Calendario</span></Link>
//                           <Link to="/dashboard/premium" className={location.pathname.includes("premium")?"list-group-item d-flex gap-1 bg-white text-dark":"list-group-item d-flex gap-1"}><i className="bi bi-people"></i><span className='d-none d-md-block'>Alunos</span></Link> */}
//                           </>:""}
                         
//                       {/* <Link to="/dashboard/premium" className="list-group-item d-flex"><i className="bi bi-patch-check"></i>Verificado</Link> */}
                          
//                           <Link to="/dashboard/profile" className={location.pathname.includes("profile")?"list-group-item d-flex gap-1 bg-white text-dark":"list-group-item d-flex gap-1"} style={{whiteSpace:"nowrap"}}><i className="bi bi-person fa-fw me-2" /><span className='d-none d-md-block'>Minha Conta</span></Link>
//                           {/* <button onClick={()=>{}} className="list-group-item d-flex text-danger gap-1" style={{whiteSpace:"nowrap"}}><i className="bi bi-person-check fa-fw" /> <span className='d-none d-md-block'>Activar a Conta</span></button> */}
//                           {/* <button onClick={()=>{localStorage.clear();window.location.href = '/';}} className="list-group-item d-flex gap-1"><i className="bi bi-power me-2" /> <span className='d-none d-md-block'>Sair</span></button> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//   </React.Fragment>);
// }

function randomNumberInRange(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function Slides({category,message,defValue,onChange,productsCheck,invitsList,instructors,profile,day,SetblibkNumber}){
  const location = useLocation();
  const [sectionId,SetsectionId] = React.useState([{uid:44009,dia:"Segunda-Feira",horaInit:"",horaEnd:""}]);
  const base_user_info = getDecodedToken();
  const diaRef = React.useRef();
  const horaInitRef = React.useRef();
  const horaFimRef = React.useRef();
  const [weekDays,SetweekDays] = React.useState(["Segunda-Feira","Terça-Feira","Quarta-Feira","Quinta-Feira","Sexta-Feira"]);
  const [weekDaysAdded,SetweekDaysAdded] = React.useState([]);

  const [stateEnvits,SetstateEnvits] = React.useState(location.pathname.includes("acepted")?false:true);

  const [currentMonth, setCurrentMonth] = React.useState('');

  // Dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  React.useEffect(() => {
    const currentDate = new Date();
    const options = { month: 'long', year: 'numeric', timeZone: 'UTC' };
    const currentMonthFormatted = new Intl.DateTimeFormat('pt-PT', options).format(currentDate);
    setCurrentMonth(currentMonthFormatted);
  }, []);




  const avatarProfile = (avatar,day)=>{

    return (  
          <ul class="avatar-group mb-0">
               {avatar?.map((value,id)=>JSON.parse(value?.details)?.date?.split("-")[2] == day?(<li class="avatar avatar-sm"><img class="avatar-img rounded-circle border-white" src={value?.user?.profileImage?value?.user?.profileImage:"https://ui-avatars.com/api/?name="+value?.user?.nome} alt="avatar"/></li>):(<></>))}
					</ul>);
  }

 // AccountManagment(base_user_info, handleClickOpen, setcurrentprice, currentprice, profile, UpdateUserData, open, handleClose));
  switch(category){
    case "invits":
       return (<React.Fragment>
            
          </React.Fragment>);
         
    case "messages":
      console.log(message);
      return (<React.Fragment>
           <div className="table-responsive border-0">
                 <div className="m-3">
                 <div className="card-header bg-transparent border-bottom">
                     <h4 className="mb-0">Messagens</h4>
                   </div>
                   {message?.length<= 0? <tr className='text-dark w-100 text-center'>
                    <td className='p-4'>Não tem mensagens!</td>
                  </tr>:
                     <table className="table table-dark-gray align-middle p-4 mb-0"> 
                      <tbody>
                      {productsCheck.length <1?<div className='w-100 mt-5 d-flex justify-content-center align-items-center h-100px'><HashLoader color="#831caf" size={30} loading/></div>:
                      productsCheck.map((item,index)=>(<MessageCard item={item}/>))}
                      </tbody>
                    </table>}
                 </div>
               </div>
         </React.Fragment>);
    case "inbox":
      return (<Messages instructors={instructors}  SetblibkNumber={SetblibkNumber} />);

  }
 
}


function ListItem({item}){
  return (<React.Fragment>
                                <tr>
                              {/* Table data */}
                              <td>
                                <div className="d-flex align-items-center position-relative">
                                  {/* Image */}
                                  <div className="avatar avatar-md mb-2 mb-md-0 rounded-circle" style={{background:"url('"+(item?.user?.profileImage==""?"https://ui-avatars.com/api/?name="+item.user?.nome:item.user?.profileImage)+"')",backgroundSize:"cover"}}></div>
                                  <div className="mb-0 ms-2">
                                    {/* Title */}
                                    <h6 className="mb-0">
                                      <Link
                                        to={"/dashboard/inbox/"+item.user.id}
                                        className="stretched-link text-capitalize">
                                        {item?.user?.nome} {item?.user?.ultimoNome}
                                      </Link>
                                    </h6>
                                    {/* Address */}
                                    <span className="text-body small">
                                      <i className="fas fa-fw fa-map-marker-alt me-1 mt-1" />
                                      {item.user.provincia}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {/* Table data */}
                              <td className="text-center text-sm-start">
                                <div className=" overflow-hidden">
                                  <span className="mb-0"> {item.accepted == 1?"Aceite!":"A espera da confirmação"}</span>
                                  {/* <div className="progress progress-sm bg-primary bg-opacity-10">
                                    <div
                                      className="progress-bar bg-primary aos"
                                      role="progressbar"
                                      data-aos="slide-right"
                                      data-aos-delay={200}
                                      data-aos-duration={1000}
                                      data-aos-easing="ease-in-out"
                                      style={{ width: "85%" }}
                                      aria-valuenow={85}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    ></div>
                                  </div> */}
                                </div>
                              </td>
                              {/* Table data */}
                              <td>{timeSet(item.date)}</td>
                              {/* Table data */}
                              <td>
                                <Link
                                  to={"/dashboard/inbox/"+item.user.id}
                                  className="btn btn-success-soft btn-round me-1 mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Message"
                                >
                                  <i className="far fa-envelope" />
                                </Link>
                                <button
                                  className="btn btn-danger-soft btn-round mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Block"
                                >
                                  <i className="fas fa-ban" />
                                </button>
                              </td>
                              <td>
                              <button
                                  className="btn mainColor btn-round mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top">
                                <i className="bi bi-camera-video"></i>
                                </button>
                              </td>
                            </tr>
  </React.Fragment>);
}

function MessageCard({item}){
  return (<React.Fragment>
                                <tr>
                              {/* Table data */}
                              <td>
                                <div className="d-flex align-items-center position-relative">
                                  {/* Image */}
                                  <div className="avatar avatar-md mb-2 mb-md-0 rounded-circle" style={{background:"url('"+(item?.user?.profileImage)+"')",backgroundSize:"cover"}}>
                                    
                                  </div>
                                  <div className="mb-0 ms-2">
                                    {/* Title */}
                                    <h6 className="mb-0">
                                      <Link
                                        to={"/dashboard/inbox/"+item?.user?.id}
                                        className="stretched-link">
                                          <span className='text-capitalize'>
                                            {item?.user?.nome} {item?.user?.ultimoNome}
                                          </span>
                                      </Link>
                                    </h6>
                                    {/* Address */}
                                    <span className="text-body small d-flex gap-2">
                                      <i class="bi bi-clock"></i>
                                      {timeSet(item?.date)}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              {/* Table data */}
                              <td className="text-center text-sm-start">
                                <div className="w-100px">
                                  <p></p>
                                  {/* <p className="mb-0">{item.message}</p> */}
                                  {/* <div className="progress progress-sm bg-primary bg-opacity-10">
                                    <div
                                      className="progress-bar bg-primary aos"
                                      role="progressbar"
                                      data-aos="slide-right"
                                      data-aos-delay={200}
                                      data-aos-duration={1000}
                                      data-aos-easing="ease-in-out"
                                      style={{ width: "85%" }}
                                      aria-valuenow={85}
                                      aria-valuemin={0}
                                      aria-valuemax={100}
                                    ></div>
                                  </div> */}
                                </div>
                              </td>

                              <td>
                                <Link
                                   to={"/dashboard/inbox/"+item?.user?.id}
                                  className="btn btn-success-soft btn-round me-1 mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Message"
                                >
                                  <i className="far fa-envelope" />
                                </Link>
                    
                              </td>
                             
                            </tr>
  </React.Fragment>);
}


const addAvaiability = (data) => {
  var config = {
      method: 'POST',
      url: 'https://api.eureca.ao/v0.1/instructor/avealbility',
      headers: headers,
      data:JSON.stringify(data)
  };
  axios(config)
  .then(function (response) {
  
  })
  .catch(function (error) {
      console.log(error);
  });

}

export function useDisableZoomOnInputFocus() {
  useEffect(() => {
    function handleFocus() {
      const meta = document.querySelector('meta[name="viewport"]');
      meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=0');
    }

    function handleBlur() {
      const meta = document.querySelector('meta[name="viewport"]');
      meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    }

    const inputElements = document.querySelectorAll('textarea');
    inputElements.forEach((input) => {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    });

    return () => {
      inputElements.forEach((input) => {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      });
    };
  }, []);
}
